@import "variables";

.portrait {
  filter: brightness(110%);

  @media (prefers-color-scheme: light) {
    filter: brightness(140%);
  }

  img {
    float: right !important;
    width: 250px;
    margin-left: $line-spacing / 4;
    margin-bottom: $line-spacing / 4;
    filter: drop-shadow(0 0 0.1rem $dark-header-background-color);

    @media (prefers-color-scheme: light) {
      filter: drop-shadow(0 0 0.1rem $dark-nav-background-color);
    }

    @media (max-width: $small-screen-threshold / 1.05) {
      display: none;
    }
  }
}

$light-alternate-index-color-1: darken($light-background-color, 10%);
$light-alternate-index-color-2: darken($light-background-color, 3%);

ul.hfill:nth-child(2n + 1) {
  background-color: $light-alternate-index-color-1;

  code {
    background-color: $light-alternate-index-color-2;
  }
}
ul.hfill:nth-child(2n) {
  background-color: $light-alternate-index-color-2;

  code {
    background-color: $light-alternate-index-color-1;
  }
}

$dark-alternate-index-color-1: darken($dark-background-color, 10%);
$dark-alternate-index-color-2: darken($dark-background-color, 3%);

@media (prefers-color-scheme: dark) {
  ul.hfill:nth-child(2n + 1) {
    background-color: $dark-alternate-index-color-1;

    code {
      background-color: $dark-alternate-index-color-2;
    }
  }
  ul.hfill:nth-child(2n) {
    background-color: $dark-alternate-index-color-2;

    code {
      background-color: $dark-alternate-index-color-1;
    }
  }
}

ul.hfill {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-left: $line-spacing / 5;
  padding-right: $line-spacing / 5;
  margin-top: $line-spacing / 3;
  margin-bottom: $line-spacing / 3;
  padding-top: $line-spacing / 5;
  padding-bottom: $line-spacing / 5;
  border-radius: $line-spacing / 5;

  li:nth-child(2) {
    text-align: right;
    padding-left: $line-spacing;

    @media (max-width: $small-screen-threshold) {
      padding-left: 0;
    }
  }
}
